import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RichText } from '../richTextOptions';
import ContentfulBlockCoverageHighlightItem from './ContentfulBlockCoverageHighlightItem';
import { useDispatch } from 'react-redux';
import { useOutsideHandling } from '../Hooks/CustomHooks';
import { dynamicSort } from '../utilities';
import Accordion from 'react-bootstrap/Accordion';

const CoverageHighlightTabContent = ({ content, headline }) => {
    const [tabCurrent, setTabCurrent] = useState(0);
    const [tabCurrentMobile, setTabCurrentMobile] = useState(0);

    return (
        <>
            {
                headline && (
                    <div className="headline">
                        <p>{headline}</p>
                    </div>

                )
            }
            <div className="tab-content-wrapper">
                {
                    content.map((coverage, index) => {
                        // const { nameCoverage, logoSvgAnchor } = coverage
                        let nameCoverage = coverage.nameCoverage;
                        let logoSvgAnchor = coverage.logoSvgAnchor;
                        if (coverage.sys) {
                            nameCoverage = coverage.fields.nameCoverage;
                            logoSvgAnchor = coverage.fields.logoSvgAnchor;
                        }
                        return (
                            <div key={index} className={`tab-content is-desktop ${tabCurrent == index ? "active" : ''}`}>
                                <div className={`tab-wrapper`}>
                                    {tabCurrent == index && (<div className="overlay"></div>)}
                                    <button className="tab" onClick={() => { setTabCurrent(index) }}>
                                        <p>
                                            {
                                                (logoSvgAnchor && coverage.sys ? logoSvgAnchor : logoSvgAnchor.childMarkdownRemark.html) && <span className="logo-anchor" dangerouslySetInnerHTML={{ __html: coverage.sys ? logoSvgAnchor : logoSvgAnchor.childMarkdownRemark.html }}></span>
                                            }
                                            {nameCoverage}
                                        </p>
                                    </button>
                                    <img loading="lazy" src="/images/icons/icon-dropdown.svg" onClick={() => { tabCurrent == index ? setTabCurrent(-1) : setTabCurrent(index) }} />
                                </div>
                                <div className="coverage-content">
                                    <div key={coverage.id} className={`coverage-wrapper ${tabCurrent == index ? "show" : ''}`}>
                                        <ContentfulBlockCoverageHighlightItem coverage={coverage} layout="Tab Content" />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <Accordion defaultActiveKey={tabCurrentMobile}>
                    {
                        content.map((coverage, index) => {
                            // const { nameCoverage, logoSvgAnchor } = coverage
                            let nameCoverage = coverage.nameCoverage;
                            let logoSvgAnchor = coverage.logoSvgAnchor;
                            if (coverage.sys) {
                                nameCoverage = coverage.fields.nameCoverage;
                                logoSvgAnchor = coverage.fields.logoSvgAnchor;
                            }
                            
                            return (
                                <div key={index} className={`tab-content is-mobile ${tabCurrentMobile == index ? "active" : ''}`}>
                                    <Accordion.Toggle
                                        as="button"
                                        aria-label="toggle"
                                        eventKey={index}
                                        onClick={() => { tabCurrentMobile !== index ? setTabCurrentMobile(index) : setTabCurrentMobile(-1) }}

                                    >
                                        <div className={`tab-wrapper `}>
                                            <div className="tab">
                                                <p>
                                                    {
                                                        (logoSvgAnchor && coverage.sys ? logoSvgAnchor : logoSvgAnchor.childMarkdownRemark.html) && <span className="logo-anchor" dangerouslySetInnerHTML={{ __html: coverage.sys ? logoSvgAnchor : logoSvgAnchor.childMarkdownRemark.html }}></span>
                                                    }
                                                    {nameCoverage}
                                                </p>
                                            </div>
                                            <img loading="lazy" src="/images/icons/icon-dropdown.svg" alt="toggle icon" />
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse className={`${tabCurrentMobile === index ? 'show ' : ''}`} eventKey={index}>
                                        <div className="coverage-content">
                                            <div className={`coverage-wrapper`}>
                                                <ContentfulBlockCoverageHighlightItem coverage={coverage} layout="Tab Content" />
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            )
                        })
                    }
                </Accordion>
            </div>
        </>
    )
}

const CoverageHighlightFullWidth = ({ content }) => {
    return (
        <>
            {
                content.map((coverage, index) => {
                    let nameCoverage = coverage.nameCoverage;
                    let bgColor = coverage.backgroundColor;
                    if (coverage.sys) {
                        nameCoverage = coverage.fields.nameCoverage;
                        bgColor = coverage.fields.backgroundColor;
                    }
                    return (
                        <div id={nameCoverage.toLowerCase().replace(/\s/g, '')} key={index} className="full-width-content" style={{ backgroundColor: bgColor }}>
                            <ContentfulBlockCoverageHighlightItem coverage={coverage} addContainer={true} layout="Full Width" />
                        </div>
                    )
                })
            }
        </>
    )
}

const CoverageHighlightSelectAnchor = ({ content, headline }) => {
    const dispatch = useDispatch();
    const [conditionCommonList, setConditionCommonList] = useState([]);
    const [openSelect, setOpenSelect] = useState(false);
    const [valueSelect, setValueSelect] = useState();

    const selectRef = useRef(null);

    const toggleDropdown = useCallback(() => {
        setTimeout(() => {
            setOpenSelect(!openSelect);
        }, 100);
    }, [openSelect]);

    const handleClickOutsideSelect = useCallback(() => {
        setOpenSelect(false);
    }, []);
    useOutsideHandling(selectRef, handleClickOutsideSelect);

    const filterConditionCommonList = () => {
        let conditionCommonList = []

        content.forEach(coverage => {
            coverage = coverage.fields ? coverage.fields : coverage
            conditionCommonList = conditionCommonList.concat(coverage.coverageConditions.filter(condition => {
                if (condition.commonCondition === "Yes") {
                    condition.nameCoverage = coverage.nameCoverage;
                    return true
                }
            })
            )
        })

        setConditionCommonList(conditionCommonList.sort(dynamicSort('nameCondition')))
    }

    useEffect(filterConditionCommonList, []);

    const handleAnchorCoverage = (nameCoverage) => {
        const coverageSelected = document.getElementById(nameCoverage);
        const selectedOffsetTop = coverageSelected.offsetTop - 70;
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

        if (isIE11) {
            window.scrollTo(0, selectedOffsetTop);
        } else {
            window.scrollTo({
                top: selectedOffsetTop,
                behavior: 'smooth',
            })
        }
    }

    const handleAnchorAndActiveCondition = (activeId, nameCoverage, nameCondition) => {
        setOpenSelect(false);
        setValueSelect(nameCondition);
        handleAnchorCoverage(nameCoverage.toLowerCase().replace(/\s/g, ''));
        dispatch({ type: 'SET_ACTIVE_COVERAGE_CONDITION', payload: { name: nameCoverage, activeId } });
    }

    return (
        <div className="select-anchor">
            {
                headline && (
                    <div className="headline">
                        <RichText data={headline}></RichText>
                    </div>

                )
            }

            <div className="select-wrapper">
                <div className="select-common-condition">
                    <div className="title"><p>Jump to a common condition:</p></div>
                    <div className="select-box" ref={selectRef}>
                        <div className="select-text">
                            <button onClick={() => toggleDropdown()}>{valueSelect ? valueSelect : 'Select a condition'}</button>
                            <div onClick={() => toggleDropdown()} className={`arrow-down ${openSelect ? 'rotate' : ''}`}></div>
                        </div>
                        <div className="list-select-wrapper">
                            <div className={`${openSelect ? 'show' : ''}`}>
                                <div className={`list-select`}>
                                    {
                                        conditionCommonList.length > 0 && conditionCommonList.map((condition, index) => {
                                            return (
                                                <div key={index} className="select-item">
                                                    <button tabIndex={openSelect ? 0 : -1} onClick={() => handleAnchorAndActiveCondition(condition.id, condition.nameCoverage, condition.nameCondition)}>{condition.nameCondition}</button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="anchor-coverage">
                    <div className="title"><p>See coverage highlights:</p></div>
                    <div className="anchor-wrapper">
                        {
                            content.map((coverage, index) => {
                                let logoSvgAnchor
                                let nameCoverage
                                if (coverage.fields) {
                                    logoSvgAnchor = coverage.fields.logoSvgAnchor
                                    nameCoverage = coverage.fields.nameCoverage
                                } else {
                                    logoSvgAnchor = coverage.logoSvgAnchor.childMarkdownRemark.html
                                    nameCoverage = coverage.nameCoverage
                                }
                                return (
                                    <div key={index} className="anchor">
                                        <button onClick={() => handleAnchorCoverage(nameCoverage.toLowerCase().replace(/\s/g, ''))}>
                                            {
                                                logoSvgAnchor && <span dangerouslySetInnerHTML={{ __html: logoSvgAnchor }}></span>
                                            }
                                            {nameCoverage}
                                        </button>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
            </div>

        </div>
    )
}

const ContentfulBlockCoverageHighlight = (block) => {
    let content = '';
    const { headlineCoverageHighlight, headlineSelectAnchor, layout, coverageHighlightItems } = block;

    switch (layout) {
        case 'Tab Content':
            content = <CoverageHighlightTabContent headline={headlineCoverageHighlight} content={coverageHighlightItems} />;
            break;
        case 'Full Width':
            content = <CoverageHighlightFullWidth content={coverageHighlightItems} />;
            break;
        case 'Select Anchor':
            content = <CoverageHighlightSelectAnchor headline={headlineSelectAnchor} content={coverageHighlightItems} />;
            break;
    }

    return (
        <div className="coverage-highlight">
            {content}
        </div>
    )
}

export default ContentfulBlockCoverageHighlight;
