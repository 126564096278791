import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Tooltip } from './Common/Tooltip';

const ContentfulBlockCoverageHighlightChart = ({ condition, donutColor }) => {
    // const roundToZero = (num) => {
    //     return +(Math.round(num + "e+1") + "e-1");
    // }

    const reimbursement = condition.vetBillAmount - condition.youPayOnlyAmount;
    const rateChart = parseInt(condition.youPayOnlyAmount) / parseInt(condition.vetBillAmount)
    const options = {
        tooltips: {
            enabled: false
        },
        cutoutPercentage: 76,
    };

    const data = {
        datasets: [{
            data: [rateChart, 1 - rateChart],
            backgroundColor: [
                '#acabab',
                donutColor || '#0047bb'
            ],
            hoverBackgroundColor: [
                '#acabab',
                donutColor || '#0047bb'
            ],
            borderWidth: 0,
        }],
    }

    return (
        <div className="feature-condition" >
            <div className="title">
                <p>Reimbursement example</p>
            </div>
            <div className="main-content">
                <div className="left">
                    <div className="veterinary">
                        <p>Veterinary bill</p>
                        <p className="dolar">${parseInt(condition.vetBillAmount).toLocaleString('en-US')}</p>
                    </div>
                    <div className="pay">
                        {parseInt(condition.youPayOnlyAmount) === 0 ? (<p>You pay</p>) : (
                            <p>You pay only</p>
                        )}
                        <p className="dolar">${parseInt(condition.youPayOnlyAmount).toLocaleString('en-US')}</p>
                    </div>
                    <div className="desc is-desktop">
                        {condition.terms && (
                            <p>{condition.terms}</p>
                        )}
                    </div>
                </div>
                <div className="right">
                    <div className="name-condition">
                        <p className="name">{condition.nameCondition}<Tooltip content={condition.tooltip} /></p>
                    </div>
                    <div className="chart-wrapper">
                        <div className="reimbursement">
                            <p>Reimbursement</p>
                            <p className="dolar">${reimbursement.toLocaleString('en-US')}</p>
                        </div>
                        <Doughnut aria-label="chart reimbursement" data={data} width={250} height={250} options={options} />
                    </div>

                </div>
            </div>
            <div className="desc is-mobile">
                {condition.terms && (
                    <p>{condition.terms}</p>
                )}
            </div>
        </div >
    )
}

export default ContentfulBlockCoverageHighlightChart;
