import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SvgIcon } from './Common/SvgIcon';
import ContentfulBlockCoverageHighlightChart from './ContentfulBlockCoverageHighlightChart';
import uniqBy from 'lodash/uniqBy';
import { addSlashToStr } from '../utilities';
import { RichText } from '../richTextOptions';

const Condition = ({ coverageConditions, stateCoverage, conditionActiveId, colorDonutAndActive, handleSetActiveCondition }) => {
    return (
        coverageConditions.map((condition, index) => {
            if (condition.id) {
                return (
                    <div key={index} className={`condition-wrapper ${condition.id == (stateCoverage?.activeId || conditionActiveId) ? 'active' : ''}`}>
                        <button
                            className={`condition`}
                            onClick={() => handleSetActiveCondition(condition.id)}
                            style={{ backgroundColor: condition.id == (stateCoverage?.activeId || conditionActiveId) ? colorDonutAndActive : 'transparent' }}
                        >
                            <div className="icon-check" style={{ color: colorDonutAndActive }}><SvgIcon type='check' /></div>
                            {condition.nameCondition}
                        </button>
                    </div>
                )
            }
            return (
                condition.andMoreLink && (
                    <div key={index} className={`condition-wrapper`}>
                        <a href={addSlashToStr(condition.andMoreLink)} className={`condition`} >
                            {condition.andMoreText}
                        </a>
                    </div>
                )

            )
        })

    )
}

const ListCondition = ({ coverageConditions, stateCoverage, conditionActiveId, colorDonutAndActive, handleSetActiveCondition, layout, andMoreLink }) => {
    coverageConditions.push(andMoreLink)
    if (layout === 'Full Width') {
        const Col3First = coverageConditions.slice(0, Math.ceil(coverageConditions.length / 3))
        const coverageConditionsSecond = Col3First.length && coverageConditions.slice(Col3First.length)
        const Col3Second = coverageConditionsSecond.length && coverageConditionsSecond.slice(0, Math.ceil(coverageConditionsSecond.length / 2))
        const Col3Third = Col3Second.length > 0 && coverageConditions.slice(Col3First.length + Col3Second.length)
        const Col2First = coverageConditions.slice(0, Math.ceil(coverageConditions.length / 2))
        const Col2Second = Col2First.length && coverageConditions.slice(Col2First.length)

        return (
            <>
                <div className="list-condition col3">
                    <div className="col-4">
                        <Condition
                            coverageConditions={Col3First}
                            stateCoverage={stateCoverage}
                            conditionActiveId={conditionActiveId}
                            colorDonutAndActive={colorDonutAndActive}
                            handleSetActiveCondition={handleSetActiveCondition}
                        />
                    </div>
                    <div className="col-4">
                        <Condition
                            coverageConditions={Col3Second}
                            stateCoverage={stateCoverage}
                            conditionActiveId={conditionActiveId}
                            colorDonutAndActive={colorDonutAndActive}
                            handleSetActiveCondition={handleSetActiveCondition}
                        />
                    </div>
                    <div className="col-4">
                        <Condition
                            coverageConditions={Col3Third}
                            stateCoverage={stateCoverage}
                            conditionActiveId={conditionActiveId}
                            colorDonutAndActive={colorDonutAndActive}
                            handleSetActiveCondition={handleSetActiveCondition}
                        />
                    </div>
                </div>
                <div className="list-condition col2">
                    <div className="col-6">
                        <Condition
                            coverageConditions={Col2First}
                            stateCoverage={stateCoverage}
                            conditionActiveId={conditionActiveId}
                            colorDonutAndActive={colorDonutAndActive}
                            handleSetActiveCondition={handleSetActiveCondition}
                        />
                    </div>
                    <div className="col-6">
                        <Condition
                            coverageConditions={Col2Second}
                            stateCoverage={stateCoverage}
                            conditionActiveId={conditionActiveId}
                            colorDonutAndActive={colorDonutAndActive}
                            handleSetActiveCondition={handleSetActiveCondition}
                        />
                    </div>
                </div>
            </>
        )

    } else if (layout === 'Tab Content') {
        const Col2First = coverageConditions.slice(0, Math.ceil(coverageConditions.length / 2))
        const Col2Second = Col2First.length && coverageConditions.slice(Col2First.length)
        return (
            <div className="list-condition col2">
                <div className="col-6">
                    <Condition
                        coverageConditions={Col2First}
                        stateCoverage={stateCoverage}
                        conditionActiveId={conditionActiveId}
                        colorDonutAndActive={colorDonutAndActive}
                        handleSetActiveCondition={handleSetActiveCondition}
                    />
                </div>
                <div className="col-6">
                    <Condition
                        coverageConditions={Col2Second}
                        stateCoverage={stateCoverage}
                        conditionActiveId={conditionActiveId}
                        colorDonutAndActive={colorDonutAndActive}
                        handleSetActiveCondition={handleSetActiveCondition}
                    />
                </div>
            </div>
        )
    }
}

const ContentfulBlockCoverageHighlightItem = ({ coverage, addContainer, layout }) => {
    const dispatch = useDispatch();

    let nameCoverage = coverage.nameCoverage;
    let headline = coverage.headline;
    let colorDonutAndActive = coverage.colorDonutAndActive;
    let logoSvg = coverage.logoSvg && coverage.logoSvg.childMarkdownRemark.html
    let andMoreText = coverage.andMoreText
    let andMoreLink = coverage.andMoreLink
    if (coverage.sys) {
        nameCoverage = coverage.fields.nameCoverage;
        headline = coverage.fields.headline;
        colorDonutAndActive = coverage.fields.colorDonutAndActive;
        logoSvg = coverage.fields.logoSvg
        andMoreText = coverage.fields.andMoreText
        andMoreLink = coverage.fields.andMoreLink
    }

    let coverageConditions = uniqBy(coverage.coverageConditions, 'id');
    const stateCoverage = useSelector(state => state.app.coverage[nameCoverage]);
    let conditionFeature = coverageConditions.find(condition => condition.featureCondition == 'Yes');
    let conditionActiveId = conditionFeature?.id || coverageConditions[0]?.id

    let coverageConditionsPreview = {};
    if (coverage.sys) {
        coverageConditionsPreview = coverage.fields.coverageConditions.map(cov => {
            return {
                ...cov.fields,
                id: cov.sys.id
            }
        })
        coverageConditions = uniqBy(coverageConditionsPreview, 'id');
        conditionFeature = coverageConditionsPreview.find(condition => condition.featureCondition == 'Yes');
        conditionActiveId = conditionFeature?.id || coverageConditionsPreview[0]?.id
    }

    useEffect(() => {
        const payload = {
            name: nameCoverage,
            coverageConditions,
            activeId: ""
        };
        dispatch({ type: 'SET_COVERAGE', payload });
    }, [])


    const handleSetActiveCondition = (activeId) => {
        dispatch({ type: 'SET_ACTIVE_COVERAGE_CONDITION', payload: { name: nameCoverage, activeId } });
    }


    return (
        <div className={`wrapper ${addContainer ? 'container' : ''}`} style={{ color: colorDonutAndActive, '--section-color': colorDonutAndActive }}>
            <div className="left">
                <div className="headline">
                    {logoSvg && <div className="icon-condition" dangerouslySetInnerHTML={{ __html: logoSvg }}></div>}
                    <h2>{nameCoverage}</h2>
                </div>
                <div className="sub-headline"><p>{headline}</p></div>
                <div className="list-condition-wrapper">
                    {
                        coverageConditions && (
                            <ListCondition
                                coverageConditions={coverageConditions}
                                stateCoverage={stateCoverage}
                                conditionActiveId={conditionActiveId}
                                colorDonutAndActive={colorDonutAndActive}
                                handleSetActiveCondition={handleSetActiveCondition}
                                andMoreLink={{ andMoreLink, andMoreText }}
                                layout={layout}
                            />
                        )
                    }
                </div>
                {
                    coverage.descriptionCondition && (
                        <div className="description">
                            <RichText data={coverage.descriptionCondition}></RichText>
                        </div>

                    )
                }
            </div>
            <div className="right">
                {
                    coverageConditions.map((condition, index) => {
                        if (condition.id == (stateCoverage?.activeId || conditionActiveId)) {
                            return (
                                <ContentfulBlockCoverageHighlightChart key={index} condition={condition} donutColor={colorDonutAndActive} />
                            )
                        }
                        return (
                            <Fragment key={index}></Fragment>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ContentfulBlockCoverageHighlightItem
